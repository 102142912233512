import { JsonPipe, NgClass } from '@angular/common';
import { Component, input, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Button, ButtonModule, LinkComponent } from '@teamfoster/sdk/button';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { IconComponent } from '@teamfoster/sdk/icon';
import { Image, ImageModule } from '@teamfoster/sdk/image';
import { StripHtmlPipe, TruncatePipe } from '@teamfoster/sdk/text-utility';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  styleUrls: ['./header.component.scss'],
  imports: [
    RouterModule,
    LinkComponent,
    JsonPipe,
    IconComponent,
    DictionaryNgrxModule,
    ButtonModule,
    ImageModule,
    NgClass,
    StripHtmlPipe,
    TruncatePipe,
  ],
  providers: [StripHtmlPipe, TruncatePipe],
})
export class HeaderComponent {
  button = input<Button>();
  secondaryButton = input<Button>();
  title = input.required<string>();
  subtitle = input<string>();
  intro = input<string>();
  image = input<Image>();

  inview$ = signal(false);
}
