<header class="header" [class.section-outset-b]="image()">
  <div class="container">
    <div class="row align-items-center flex-column-reverse flex-md-row">
      <div class="col-md-12 col-xl-11 header__content">
        <ng-content select="[breadcrumb]"></ng-content>

        @if (subtitle()) {
          <strong class="h6 d-block header__subtitle mb-3 mb-md-3">{{ subtitle() }}</strong>
        }
        <h1 class="h1">{{ title() }}</h1>

        @if (intro(); as intro) {
          <p class="mt-3 mt-md-3 header__intro">{{ intro | stripHtml }}</p>
        }

        <ng-content></ng-content>

        @if (button()?.url || secondaryButton()?.url) {
          <footer class="mt-3 d-flex flex-wrap gap-2 header__main__payoff__footer">
            @if (button()?.url) {
              <fstr-link [button]="button()!" [cssClasses]="'button button--primary'"></fstr-link>
            }
            @if (secondaryButton()?.url) {
              <fstr-link [button]="secondaryButton()!" [cssClasses]="'button button-link-chip button--secondary'">
                <span><fstr-icon [anchor]="'icon-arrow-right'"></fstr-icon></span>
              </fstr-link>
            }
          </footer>
        }
      </div>
      @if (image(); as image) {
        @if (image.url) {
          <figure class="header__media mb-3 mb-md-0 col-md-11 offset-md-1 col-xl-11 offset-xl-2">
            <div class="d-block pull-e header__media__wrapper">
              <fstr-image
                [image]="image"
                [format]="'webp'"
                [priority]="true"
                [height]="550"
                [width]="715"
                [alt]="image.alt || title()"
                [scale]="'both'"
                [colSpan]="{ xs: 5, md: 4, lg: 4 }"
              ></fstr-image>
              <fstr-icon anchor="icon-eurocross-beeldmerk" class="header__media__icon"></fstr-icon>
            </div>
          </figure>
        }
      }
    </div>
    <ng-content select="[footer]"></ng-content>
  </div>
</header>
